export const USER_AUTH = 'USER_AUTH';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const ADD_PLAYER = 'ADD_PLAYER';
export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const NEW_GAME = 'NEW_GAME';
export const SHOW_GAME = 'SHOW_GAME';
export const UPDATE_GAME = 'UPDATE_GAME';
export const LIST_GAMES = 'LIST_GAMES';
export const CANCEL_GAME = 'CANCEL_GAME';
export const SEND_EMAILS = 'SEND_EMAILS';
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT';
export const SAVE_VENUE = 'SAVE_VENUE';
export const LIST_VENUES = 'LIST_VENUES';
export const SEND_PAYOUTS = 'SEND_PAYOUTS';
export const GET_PAYOUTS = 'GET_PAYOUTS';
export const PAYMENTS_ERROR = 'PAYMENTS_ERROR';
export const GET_PAYMENTS_TOTAL = 'GET_PAYMENTS_TOTAL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ROUTE_CHANGE = 'ROUTE_CHANGE';
export const ADMIN_STATS = 'ADMIN_STATS';
export const SEND_REFUND = 'SEND_REFUND';
