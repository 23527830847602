import React from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    this.props.routeChange('/privacy');
  }
   render() {
    return (
    <div className='container-fluid' style={{padding:'20px'}}>
      <h1>Privacy Policy</h1>
      <h3>Overview</h3> 
      <p>www.hockeycompass.com (“our Website”) is a service provided by Hockeycompass, LLC (the “Company”) for the following purpose: Help bring people together through sports by providing an easy to use platform for people to post and join sporting events. Even as the Internet allows us to communicate and exchange information far more easily than in the past, it also generates new issues and challenges that must be addressed in this ever-changing digital world. One of the most important issues that any website owner must address is that of its users’ privacy. We believe that our privacy policy is one that adheres to all relevant laws, regulations and customs while at the same time is readily accessible and understandable to our users. We take great pride in the trust that our users place in our Website. For our service to be effective, we must collect certain information from our users. If you do not agree with any of the terms or practices described in this Privacy Policy, please do not use our Website.</p> 
    <h3>What does our privacy policy cover?</h3> 
    <p>This policy covers our Website’s treatment of our users’ Personally Identifiable Information (as defined below) that we collect when our users utilize our Website service. This policy does not apply to the practices of any company that the Company does not control or own, people that the Company does not employ or manage, or third-party websites that the Company does not own, control, or manage. 
    Why have a privacy policy? Our policy allows us to collect information from our users in a manner that will allow our users to retain control over their personal information, but still allow us to make their online visit safe, satisfying and rewarding. We use our users’ information to make it easier for our users to access our Website services. Since access to a user’s section on the site requires authentication of the user’s identity, some information is required to be provided for us to know who is accessing the site and which areas that person has access to. The information allows us to provide our users with a personalized interface to the data that is relevant to their user profiles. Use of any of the services on our Website is entirely voluntary; however, if you do not provide the Personally Identifiable Information requested by us, then we may elect not to make the related services available for your use. </p>
    <h3>What type of information do we collect? </h3>
    <p>There are two types of general information that are typically collected online: Anonymous Information and Personally Identifiable Information. 
    Anonymous Information is information that can not be traced back to a specific person. While we may have a hit counter on a page on our site, that counter only tracks the number of hits, it does not collect any specific information about our visitors. This is Anonymous Information. While anonymous visitors are welcome to browse, registration and agreement to our Terms of Use are required to use our personalized services including the full features of our Website. 
    Personally Identifiable Information is information that tells us who our users are and includes our users’ names, emails, zip codes, and similar information. This information is needed to allow us to personalize our users’ interfaces with our Website including the areas of the service that they are permitted to access. When we request Personally Identifiable Information from our users, we include a link to these privacy policies, and inform our users what information is required and what information is optional. 
    When you register for our Website service, you will be asked to enter certain Personally Identifiable Information including: your first and last name and zip code (Registration Data). Not all of this information may be required to register as a user. According to our Website Terms of Use, you must keep this Registration Data current in order to maintain your account. We may also obtain information from you when you participate in a promotion, make an online purchase from an advertiser or sponsor, contact us with a suggestion or complaint, or respond to an approved survey. This information continues to build your information profile. 
    What about our users who are children and their parents? The collection of Personally Identifiable Information from and about children is sometimes necessary. The Company is sensitive to the issues involved in collecting such information from minors and has taken the following steps to ensure the privacy of any minors accessing our Website. Due to the nature of the service provided by our Website, we require that certain information from children be collected in order to properly configure each user’s access to the service. Our Website established the following guidelines that operate in connection with our adherence to the Federal Children’s Online Privacy Protection Act of 1998 (COPPA): http://www.ftc.gov/ogc/coppa1.htm . At each request for children’s information there will be a link to our privacy policy and our COPPA statement. We strongly agree with the intent of COPPA and have structured our COPPA policy to enable us to collect only the information from children that is necessary for their access to our Website service; and, then only after obtaining verifiable consent from their parents. We strongly recommend that any information about children under 18 submitted to our Website be of the type that our users would typically see in their local newspaper. Accordingly, information that is not appropriate for local newspapers should not be posted to our Website. </p>
    <h3>Information Disclosure - Will we do it?</h3> 
    <p>The Company will not sell or rent your Personally Identifiable Information to anyone. However, the Company. will send Personally Identifiable Information about our users to other companies or people when:• We have the consent of our users to share the information. 
    • We need to share our users’ information to provide a product or service that the user requested. 
    • We need to send the information to third parties that provide services on our behalf to provide our users with a product or service they requested. These third parties do not have any right to use the Personally Identifiable Information we provide to them beyond what is necessary to assist us in fulfilling our users’ requests unless the third party is specifically authorized to use the information by our users. 
    • We respond to subpoenas, court orders or legal process. 
    • We find that your actions on our site violate our Terms of Use or any of our usage guidelines for our services. </p>
    <h3>Do we use cookies? </h3>
    <p>Cookies are pieces of data stored on our users’ hard drives that contain information about our users. Cookies are not linked to Personally Identifiable Information while users are on our site. Once our users close their browsers, the cookies terminate. Cookies allow our users to only log into the site once while remembering their usernames and passwords for future access. Cookies may be rejected by our users, but the rejection will result in more limited access to the services we provide since our services are personalized to our users. Cookies may also be used to allow our users access to our sweepstakes, contests and other events. Some of our advertisers utilize cookies; however, we do not have control over or access to their cookies. We use cookies to monitor site activity, not user activity. If you choose not to accept cookies, you should be aware that you may not be able to experience the full functionality of our Website. </p>
    <h3>Log Files</h3> 
    <p>We collect IP addresses from our users and visitors to track trends, administer the site, and collect broad Anonymous Information for demographic purposes. The IP addresses are not linked to Personally Identifiable Information of our users or visitors. We do not monitor the activities of our users within or without our Site. </p>
    <h3>Sharing of Information</h3> 
    <p>We may share Anonymous Information with our advertisers and partners. If we do share this information, it will not be linked to any Personally Identifiable Information. Our third-party shipping and credit card processing partners are provided with Personally Identifiable Information to enable them to properly fulfill our users’ product and service requests. Our partners do not retain, share, store or use this Personally Identifiable Information for any secondary or other purposes not directly related to fulfillment of our users’ product and service requests. In most respects, our users will deal directly with our third-party advertisers and their shipping and credit card processing partners. Therefore, our users should carefully review our advertisers’ privacy polices and terms and conditions prior to entering into a transaction with such advertisers. </p>
    <h3>Links </h3>
    <p>Our Website contains links to other sites. Some of these sites may display our product logos, our products or other content provided by the Company. Please understand that the Company is not responsible for the privacy practices of these other sites. We recommend that our users be aware when they leave our Website and that they read the privacy policies of the other sites to see how they treat the information they collect. By providing access to third-party sites or advertisements, the Company is not endorsing the products or services provided by the owners or operators of such websites. Consequently, we will not be responsible for the accuracy, relevancy, availability, reliability, copyright compliance, legality or decency of the material contained in such third-party Websites linked to our Website. We strongly encourage you to make whatever investigation you feel is necessary or 
    appropriate before proceeding with any online or offline transaction. This Privacy Policy applies only to the information collected by the Company via our Website. </p>
    <h3>Newsletters </h3>
    <p>The Company plans on publishing several newsletters that our users can subscribe to. The newsletters will be sent via email and users will need to provide contact information in order to receive the newsletters. </p>
    <h3>Surveys, Contests and Sweepstakes</h3> 
    <p>The Company may offer our users the opportunity to participate in surveys, contests and sweepstakes via our Website. In order to participate, users will be asked to provide information. Participation in these events is voluntary, thereby providing our users with a choice as to whether to provide the information requested or not. Information requested for these events may include contact information (name and address), demographic data (zip code and age), and optional information (gender, likes, dislikes). Contact information will only be used to notify the winners and to award prizes. Any survey information will be used to improve and monitor our Website service. </p> 
    <h3>Security</h3> 
    <p>The Company takes its commitment to protect our users’ information seriously. When users submit sensitive information via our Website service, the information is protected both online and offline. When our registration and order forms request users to enter sensitive information (credit card or social security numbers), the information is encrypted and is protected with SSL technology. We believe that SSL is the best encryption software available today. Any page of our site that requests this information will display a lock icon on the bottom of our users’ browsers (Netscape and Internet Explorer). 
    Even though we use SSL encryption technology to protect our users’ sensitive information online, we also employ internal procedures to protect sensitive information offline. Access to all of our users’ sensitive information is restricted to only those employees that require access (billing clerks or customer service personnel). We instruct our employees during the course of their tenure regarding protecting our users’ confidential and sensitive information and we perform periodic checks of how the employees adhere to the policies. We house our servers in a secure and locked facility that restricts access to only those employees and technicians that require access. Our security policies are periodically reviewed and enhanced as necessary. Only authorized employees and technicians have access to the information provided by our users. Due to the nature of technology, our users are also partially responsible for the security of their information. Since people have become accustomed to the Internet, browser technology has made it easier for everyone to return to their favorite sites without re-entering their usernames and passwords. While this is easier for surfing, when Personally Identifiable Information is associated with the log-in information, our users’ security could be affected. We strongly recommend that our users do not save their username and password in their browsers and that they either remember their log-in information or store their usernames and passwords separately to avoid unauthorized access to their information from the users’ computers. 
    Additional information regarding our security practices can be obtained by emailing questions to info@hockeycompass.com. 
    We also offer the following advice to our users: 
    • If you share your computer with other people, remember to log off our Website before leaving your computer. 
    • Don’t share your password with anyone. If you store your log in information in your computer, remember to password protect that information. No one from our Website will ever ask for your password. 
    • If asked, don’t give out your personal information. 
    • If you are unsure about the information you are providing, ask us, another administrator, your parents, or another person before posting the information. 
    • If there is information that you would not like to see in your local newspaper, don’t post it to our Website. 
    • When in doubt, ask us at info@hockeycompass.com. </p>
    <h3>Special Offers</h3> 
    <p>Each new registered user is welcomed with an initial email message that verifies his or her username and password. Registered users will occasionally receive information regarding products, services, newsletters or other information that we believe may be of interest to our users. Since we respect our users’ privacy, we provide all users with the ability to opt out of receiving these messages. Please see our opt-out information below. </p>
    <h3>Announcements</h3> 
    <p>As we add new services to our Website service, we will inform our users of the additional features. We will also send our users announcements regarding updates to the service and information regarding maintenance. Since these messages contain important information regarding the service, our users are not permitted to opt out of receiving these messages. </p>
    <h3>Corrections to Personal Information </h3>
    <p>If our users’ Personally Identifiable Information changes due to a move or other circumstances, or a user wishes to terminate his or her participation in our service, we will try to provide a way to correct, update or remove that user’s Personally Identifiable Information from our servers. These updates and removals can be completed on our users’ information pages by our users’ site administrator or by sending a message to info@hockeycompass.com. In extenuating circumstances, we can address these issues via telephone or regular U.S. Postal Service. Some information may remain on our servers including in our archived records after termination of an account. </p>
    <h3>Opting Out</h3> 
    <p>We value our users’ privacy and have provided them with the ability to “opt out” of having their information used for purposes not directly related to our Website service and the purposes for which the information is requested. 
    If a user who previously accepted our newsletter or promotional materials no longer wants to receive these messages, the user may opt out of receiving additional messages by replying to “unsubscribe” in the subject line of the email or by emailing us at info@hockeycompass.com. Users may also return to their information page to edit the information regarding receiving messages and newsletters and choose the opt-out option. If any user information is being shared with third parties or collected by third parties, we provide prior notice to our users, in order to allow our users to make an informed decision regarding allowing the information to be collected and proceeding with the third-party transaction or not. </p>
    <h3>Changes to our Privacy Policy</h3> 
    <p>If we decide to change our privacy policy, we will post the changes on our homepage to inform our users of what information we collect, how we use the information, and under what circumstances, if any, we disclose the information to third parties. If at any time we decide to use Personally Identifiable Information in a manner different from that stated at the time the information was collected, we will notify users by way of an email. Users will then have the opportunity to opt out of allowing us to use the information. Users that fail to respond to the change of policy message will not have their information utilized pursuant to the new policies since we will implement the policies for all information collected from the time of the change forward. We will use the information in accordance with the privacy policy under which it was collected. </p>
    <h3>Enforcement</h3> 
    <p>If you feel that our site is not following our privacy policy, you may contact us at our corporate office via telephone. You may also direct inquiries to info@hockeycompass.com. The Federal Trade Commission also has a complaint hotline that can be reached by phone at (202) 382-4357 or online at www.ftc.gov/ftc/complaint.htm. </p>
    <h3>Additional Questions</h3> 
    <p>Any additional questions regarding our privacy policy can be asked via email by sending a message to info@hockeycompass.com. </p>
      </div>
    )
  }
}

export default connect(null, actions)(PrivacyPolicy)